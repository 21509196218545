.blog-swiper {
  .swiper-pagination {
    @apply flex justify-center;
    .swiper-pagination-bullet {
      @apply w-4 h-4 bg-blackshade-700 opacity-100;
    }
    .swiper-pagination-bullet-active {
      @apply bg-gold-500 w-8 rounded-2xl;
    }
  }
}
