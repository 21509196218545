.cesium-container {
  height: calc(100vh - 130px);

  position: relative;
  @include desktop-width {  height: calc(100vh - 185px);

  }
  // padding-top: 90px;
  .cesium-viewer-animationContainer {
    display: none;
  }
  .cesium-viewer-bottom {
    display: none;
  }
  .cesium-select-btn {
    z-index: 1;
    position: absolute;
    top: 100px;
    left: 12px;
    background-color: #190d3e;
    border: none;
    outline: none;
    color: #fff;
    padding: 6px;
    border-radius: 8px;
  }
  .cesium-viewer-timelineContainer {
    display: none;
  }
  .cesium-button {
    @apply w-12 h-12 rounded-lg bg-blackshade-700 hover:bg-blackshade-600 border-none shadow-none text-gold-500;
    .cesium-svgPath-svg {
      @apply hidden;
    }
  }
  .cesium-viewer-toolbar {
    z-index: 1;
  }
  .cesium-home-button {
    &::after {
      content: "";
      background-image: url("../assets/icons/GPS.svg");
      width: 24px;
      height: 24px;
      display: flex;
      margin: 0 auto;
    }
  }
  .cesium-navigation-help-button {
    &::after {
      content: "";
      background-image: url("../assets/icons/QuestionCircle.svg");
      width: 24px;
      height: 24px;
      display: flex;
      margin: 0 auto;
    }
  }
}

.cesium-viewer-bottom {
  @apply hidden;
}
