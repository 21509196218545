// // @import 'node_modules/bootstrap/scss/bootstrap.scss';

@import "./_mixins.scss";
@import "./_button.scss";
@import "./_input.scss";
@import "./_table.scss";
@import "./_dashboard.scss";
@import "./_stepper.scss";
@import "./_accordion.scss";
@import "./_map.scss";
@import "./_swiper.scss";
@import "./_animation.scss";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Rajdhani";
  src: url("./fonts/Rajdhani-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rajdhani";
  src: url("./fonts/Rajdhani-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Rajdhani";
  src: url("./fonts/Rajdhani-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Rajdhani", sans-serif;
  overflow-x: hidden;
  @apply bg-blackshade-900 text-white;
}
.container {
  @apply mx-auto w-full max-w-full desktop:max-w-[1536px] !important;
}
.base-shaodw {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.24);
}
.dashboard-body {
  @apply bg-blackshade-900 relative;
  &:after {
    content: "";
    background: url("./images/bg-liners.svg") no-repeat;
    height: 173px;
    width: 547px;
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}
.frame-effects {
  @apply backdrop-blur-[6px];
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.24);
}

#tsparticles-first canvas {
  z-index: 1;
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
  // .animation(fade 1.2s infinite ease-in-out);
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 160px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  animation: rotate 1.2s infinite linear;
  .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 3px rgba(255, 183, 15, 0.5);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.circle {
  position: absolute;
  border-radius: 50%;
}

/* Flashing animation */
@keyframes flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flashing {
  // color: red;
  animation: flashing 1s infinite;
}
.form-select {
  @apply border-blackshade-400 p-3 rounded-2xl text-blackshade-300;
}
.text-gradiant {
  @apply bg-golden-linear bg-clip-text text-transparent;
}
.connect-btn {
  @include desktop-width {
    // display: none;
  }
  button {
    @apply text-blackshade-900 flex items-center gap-1;
    font-family: "Rajdhani", sans-serif !important;
    border-radius: 100px !important;
    background: linear-gradient(
      157deg,
      #ffb70f 26.79%,
      #8a5e0c 88.69%
    ) !important
    ;
    padding: 20px;
    color: var(--Blackshade-900, #121212) !important;
    &::before {
      content: "";
      background: url("../assets/icons/wallet.svg") no-repeat;
      width: 24px;
      height: 28px;
      display: flex;
    }
    div:nth-child(1) {
      @apply text-label-lg;
      border-radius: 100px !important;
    }
    div:nth-child(2) {
      border-radius: 100px;
      background: var(--Blackshade-700, #2e2e2e);
      border: none;
      margin: 4px;
      svg {
        @apply text-gold-500;
      }
    }
  }
}
.active-connect-btn {
  button {
    font-family: "Rajdhani", sans-serif;
    border: 1px solid var(--Gold-500, #ffb70f) !important;
    background-color: transparent !important;
    border-radius: 100px !important;
    div:nth-child(1) {
      @apply text-label-lg;
      border-radius: 100px !important;
    }
    div:nth-child(2) {
      border-radius: 100px;
      background: var(--Blackshade-700, #2e2e2e);
      border: none;
      margin: 4px;
      svg {
        @apply text-gold-500;
      }
    }
  }
}
.mobile-menu-button {
  transition: all 0.3s ease;
  display: none;
  position: relative;
  width: 20px;
  height: 20px;

  background-color: transparent;
  cursor: pointer;
  &:before,
  &:after,
  span {
    background-color: #ffb70f;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    overflow: hidden;
    text-indent: 200%;
  }
  &:before {
    -webkit-transform: translate3d(0, -7px, 0);
    -moz-transform: translate3d(0, -7px, 0);
    transform: translate3d(0, -7px, 0);
  }
  &:after {
    -webkit-transform: translate3d(0, 7px, 0);
    -moz-transform: translate3d(0, 7px, 0);
    transform: translate3d(0, 7px, 0);
  }
  &.active span {
    opacity: 0;
  }
  &.active:before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    -moz-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
  }
  &.active:after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -moz-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
  }
  @media only screen and (max-width: 1350px) {
    display: block;
  }
}
.gradient-border-box {
  --border-width: 1px;
  position: relative;
  color: white;
  background: #242424;
  border-radius: 20px;
}

.gradient-border-box::before {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    358deg,
    #5f86f200,
    #3d3d3d00,
    #f25fd008,
    #f25f6103,
    #f2cb5f12,
    #ffb70f,
    #5ff2810a,
    #5ff2f000
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: 20px;
}
.grecaptcha-badge {
  @apply invisible;
}
