.base-input {
  @apply block pb-2.5 w-full text-white bg-transparent
  rounded-2xl border border-blackshade-400 appearance-none focus:outline-none focus:ring-0 focus:!border-gold-500
 hover:!border-blackshade-300 disabled:!border-blackshade-400;
  -webkit-appearance: none; /* Removes default styling on WebKit browsers */
  appearance: none;
  &.isError {
    @apply text-red-600 border-red-700 hover:!border-red-600;
  }
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgb(0 0 0 / 0%) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  &__label {
    @apply absolute text-label-md text-blackshade-400  duration-300 transform -translate-y-4 scale-[0.90] top-8 z-10;
    @apply origin-[0] px-4 peer-focus:px-4 peer-placeholder-shown:scale-100;
    @apply peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-5 peer-focus:scale-[0.90] peer-focus:-translate-y-4;
    @apply start-1;
    &.lg {
      @apply px-4 peer-focus:px-4;
    }
    &.md {
      @apply px-3 peer-focus:px-3;
    }
  }

  &.lg {
    @apply px-4 py-2 h-14 rounded-2xl;
  }
  &.md {
    @apply px-3 py-[14px] h-12 rounded-xl;
  }
}

.form-select {
  @apply border-blackshade-400 p-3 rounded-2xl text-blackshade-300;
  @apply border rounded-2xl p-4 bg-blackshade-900 w-full outline-0;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 2.5rem; /* Add space for the icon on the right */
  &:focus {
    @apply outline-0 shadow-none;
  }
}

.form-select-wrapper {
  @apply relative w-full;

  &::after {
    content: "";
    background-image: url("../assets/icons/AltArrowDown.svg");
    background-size: 1rem; /* Adjust as needed */
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 1rem; /* Position it inside the select box */
    transform: translateY(-50%);
    pointer-events: none;
    width: 16px;
    height: 9px;
  }
}
