$n: 15; // Number of meteors
$s: 300; // Number of stars

$shadow: ();

// Generate random positions for stars
@for $i from 1 through $s {
  $x: random(1920);
  $y: random(1000);
  $shadow: $shadow, ($x + px $y + px #ffb70f) !global;
}

.star {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadow;
  position: absolute;
  z-index: -100;
  top: 0;
}

// Generate meteors with slower speed
@for $i from 1 through $n {
  $v: random(90) + 9; // left position (percentage)
  $h: random(250) + 50; // top position (pixels)
  $d: random(140) / 10 + 6; // Increase the duration for a slower effect

  .meteor-#{$i} {
    position: absolute;
    top: $h + px;
    left: $v * 1%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(
      to right,
      #ffb70f,
      rgba(255, 255, 255, 0)
    );
    animation: meteor $d + s linear infinite;
    z-index: 100;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 5px;
      border-radius: 50%;
      margin-top: -2px;
      background: rgba(#ffb70f, 0.7);
      box-shadow: 0 0 15px 3px #ffb70f;
    }
  }
}

@keyframes meteor {
  0% {
    opacity: 1;
    margin-top: -300px;
    margin-right: -300px;
  }
  12% {
    opacity: 0;
  }
  15% {
    margin-top: 300px;
    margin-left: -600px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
  }
  100% {
    transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
      rotateY(-360deg);
  }
}

.bg-stars {
  @apply absolute top-[4rem] left-1/2 -z-20;
  transform: perspective(500px);
  transform-style: preserve-3d;
  perspective-origin: 50% 100%;
  animation: rotate 90s infinite linear;
}

.bg-star {
  width: 2px;
  height: 2px;
  background: #ffd061;
  position: absolute;
  top: 10rem;
  left: 0;
  transform-origin: 0 0 -300px;
  transform: translate3d(0, 0, -300px);
  backface-visibility: hidden;
  z-index: -10;
}

:root {
  --base-speed: 40s;
  --overlay: rgba(25, 25, 25, 0.9);
  --color-grid: #ebebeb;
  --color-grid-glow: #fff6af;
  --line-width: 0.0625rem;
  --height: 15.625rem;
  --perspective: 17.625rem;
}

.bottom-overlay {
  perspective: var(--perspective);
  flex: 0 0 var(--height);
  position: absolute;
  z-index: 10000;
  right: 0;
  left: 0;
  bottom: 0;
  height: var(--height);
  background: radial-gradient(ellipse at center, transparent 50%, #121212 70%);
}

.bottom {
  background: inherit;
  perspective: var(--perspective);
  flex: 0 0 var(--height);
  position: relative;

  // grid
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
        to right,
        var(--color-grid) var(--line-width),
        transparent var(--line-width)
      ),
      linear-gradient(
        to bottom,
        var(--color-grid) var(--line-width),
        transparent var(--line-width)
      );
    background-size: 2rem 125rem, 125rem 2rem;
    transform: rotateX(53deg) scale(1.8) translateZ(43px);
    animation: grid-bottom calc(var(--base-speed) * 1) linear infinite;
    animation-fill-mode: backwards;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    border-top: 1px solid var(--color-grid);
    filter: drop-shadow(0 0 2px var(--color-grid-glow));
  }
}

@keyframes grid-bottom {
  from {
    background-position-y: -30rem;
  }

  to {
    background-position-y: 0%;
  }
}

// 🌎
.scene {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    // background: url(https://assets.codepen.io/907471/noise.svg);
    opacity: 0.7;
    mix-blend-mode: overlay;
    filter: invert(1);
    z-index: 20000;
  }
}

.drop {
  width: 400px;
  height: 400px;
  display: inline-block;
  border: 10px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 40px 5px rgb(240, 247, 255),
    0 0 40px 4px rgb(235, 245, 255) inset;
  border-radius: 100%;
  transform: rotateX(90deg);
  position: absolute;
  // bottom: -12rem;
  left: 50%;
  margin-left: -200px;
  -webkit-animation: infinite 10s wave;
  animation: infinite 10s wave;
  &:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
  &:nth-child(3) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
  }
  &:nth-child(4) {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }
}

@-webkit-keyframes wave {
  0% {
    opacity: 0;
    transform: rotateX(80deg) scale(0.2);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotateX(80deg) scale(1.3);
  }
}

@keyframes wave {
  0% {
    opacity: 0;
    transform: rotateX(80deg) scale(0.2);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotateX(80deg) scale(1.3);
  }
}

@keyframes leftLineMove {
  0% {
    transform: translateX(0) translateY(-50%) rotate(0deg); /* Start centered */
  }
  40% {
    transform: translateX(-3.5rem) translateY(-50%) rotate(0deg); /* Move outward slowly */
  }
  50% {
    transform: translateX(-3.5rem) translateY(-50%) rotate(0deg); /* Pause briefly */
  }
  70% {
    transform: translateX(0) translateY(-50%) rotate(0deg); /* Close rapidly */
  }
  85% {
    transform: translateX(-0.5rem) translateY(-50%) rotate(0deg); /* Slight bounce outward */
  }
  100% {
    transform: translateX(0) translateY(-50%) rotate(0deg); /* Return to center */
  }
}

@keyframes rightLineMove {
  0% {
    transform: translateX(0) translateY(-50%) rotate(180deg); /* Start centered */
  }
  40% {
    transform: translateX(3.5rem) translateY(-50%) rotate(180deg); /* Move outward slowly */
  }
  50% {
    transform: translateX(3.5rem) translateY(-50%) rotate(180deg); /* Pause briefly */
  }
  60% {
    transform: translateX(0) translateY(-50%) rotate(180deg); /* Close rapidly */
  }
  85% {
    transform: translateX(0.5rem) translateY(-50%) rotate(180deg); /* Slight bounce outward */
  }
  100% {
    transform: translateX(0) translateY(-50%) rotate(180deg); /* Return to center */
  }
}

@keyframes textPulse {
  0%,
  70%,
  100% {
    transform: scale(1); /* Normal size */
    color: #ffb70f;
    text-shadow: 0px 0px 5.5px #e5a000;
  }
  40%,
  50% {
    transform: scale(1.1); /* Larger size when lines are far apart */
    color: #ebebeb;
  }
  85% {
    transform: scale(1.05); /* Slight bounce when lines are moving back */
  }
}

.animate-left-line-move {
  animation: leftLineMove 2s ease-in-out infinite;
}
.animate-right-line-move {
  animation: rightLineMove 2s ease-in-out infinite;
}
.animate-text-pulse {
  display: inline-block;
  animation: textPulse 2s ease-in-out infinite;
}
