.flat-accordion {
  max-width: 740px;
  margin: 0 auto;
}
.faq-accordion .accordion-item {
  @apply p-4 bg-dashboard-frame frame-effects !rounded-3xl border border-solid border-gold-500 mb-6;
  box-shadow: none;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  &:not(:first-of-type) {
    @apply border border-solid border-gold-500;
  }

  .accordion-header {
  }

  .accordion-button {
    @apply text-subheading-md relative;

    transform: translateY(0);
    padding: 0;
    transition: all 0.3s ease;

    z-index: 1;
    border-radius: 0 !important;
    box-shadow: none;

    text-transform: capitalize;

    padding-bottom: 15px;

    margin-bottom: 16px;

    &::after {
      content: "";
      @apply w-6 h-6;
      background-image: url("../assets/icons/AltArrowDown.svg");
      background-position: center;
      font-weight: 500;
      font-size: 25px;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.collapsed {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .accordion-collapse {
    background: transparent;

    .accordion-body {
      @apply p-0 text-left text-title-md text-blackshade-200;
    }
  }

  &.corner-box {
    // @include transition3;
    padding: 28px 18px 11px;
    .toggle-title {
      // @include transition3;
      padding-bottom: 16px;
      margin-bottom: 0;
      position: relative;
      padding-right: 30px;

      &:hover {
        cursor: context-menu;
      }
    }
  }
  &.active {
    &.corner-box {
      padding: 28px 18px;
      .toggle-title {
        margin-bottom: 16px;
      }
    }
  }
}
// .accordion-button:not(.collapsed)::after {
//   transform: rotate(-180deg);
// }
