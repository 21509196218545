$radius: 55px;
$circumf: 3.1416 * $radius * 2;
$check-len: 25px;
$body-back: #2ecc71;
$cross-color: #c0392b;
.stepper-container {
  flex-grow: 1;

  .circle-container {
    position: relative;
  }

  .text-container {
    margin: 1rem 0.5rem 0;
    text-align: center;
    @include tablet {
      font-size: 10px;
      margin: 1rem 0.25rem 0;
    }
  }

  #outline {
    animation: 0.38s ease-in outline;
    transform: rotate(0deg);
    transform-origin: center;
  }

  #white-circle {
    animation: 0.35s ease-in 0.35s forwards circle;
    transform: none;
    transform-origin: center;
  }

  #check {
    animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
    stroke-dasharray: 0, $check-len;
  }

  #check-group {
    animation: 0.32s ease-in-out 1.03s check-group;
    transform-origin: center;
  }

  .line .step {
    // display: flex;
    // align-items: center;
    flex: 1;
  }

  .circle-container {
    display: grid;
    grid-template-columns: 53px;
    grid-template-rows: 53px;
    justify-content: center;
    align-content: center;
  }

  .circle {
    background-color: white;
    background-image: radial-gradient(
      circle at center,
      #ffffff 0%,
      #f2f2f2 20%,
      #cccccc 80%
    );
    height: 32px;
    width: 32px;
    border-radius: 50%;
    z-index: 2;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin: auto;
    cursor: pointer;
  }

  .pulse {
    display: none;
  }

  .circle.in-progress {
    background-image: radial-gradient(
      circle at center,
      #a2c7fc 20%,
      #5993fb 80%
    );
  }

  .pulse.in-progress {
    display: block;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 3px solid #5994fb;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    animation: pulse 1s infinite;
  }

  .circle-container {
    margin: 0 0.5rem;
    text-align: center;
  }

  .tick-box {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin: auto;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
}
.line {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &::after,
  &::before {
    @apply bg-blackshade-600;

    content: "";
    position: absolute;
    top: 28px;
    height: 4px;
    z-index: 0;
  }
  &::before {
    width: 100%;
  }
  &:first-child::before {
    left: 50%;
    width: 50%;
  }
  &:last-child {
    &::before {
      left: 0;
      width: 50%;
    }
    &.in-progress::after,
    &.in-progress::before {
      background-color: #15f3a8;
    }
  }
  &.active::before,
  &.active::after {
    @apply bg-gold-500;
  }

  &:not(:first-child) {
    &.in-progress::after {
      @apply bg-gold-500 left-0 w-1/2;
    }
    &.failed {
      &::before {
        @apply bg-gold-500 left-0 w-1/2;
        // background-color: $cross-color;
        // left: 0;
      }
    }
  }
  &:not(:last-child) {
    &.failed {
      &::after {
        @apply bg-blackshade-600 w-1/2 right-0;
      }
    }
  }
}

@keyframes outline {
  from {
    stroke-dasharray: 0, $circumf;
  }
  to {
    stroke-dasharray: $circumf, $circumf;
  }
}
@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes check-group {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  to {
    transform: scale(1);
  }
}

@keyframes check {
  from {
    stroke-dasharray: 0, $check-len;
  }
  to {
    stroke-dasharray: $check-len, $check-len;
  }
}

@import url(https://fonts.googleapis.com/css?family=Almendra);

.cross-icon {
  width: 32px;
  height: 32px;
  background-color: $cross-color;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  z-index: 2;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: auto;
  cursor: pointer;
  &.cross-delete .cross-x-mark {
    position: relative;
    display: block;
  }
  &.cross-delete .cross-delete-line {
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #fff;
    display: block;
    top: 14px;
    border-radius: 2px;
  }
  &.cross-delete .cross-delete-line.cross-delete-left {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 6px;
  }
  &.cross-delete .cross-delete-line.cross-delete-right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 6px;
  }
  /* The icons are not animated in IE. */
  &.cross-delete .cross-delete-line.cross-delete-left {
    -ms-transform: rotate(45deg) \9;
  }

  &.cross-delete .cross-delete-line.cross-delete-right {
    -ms-transform: rotate(-45deg) \9;
  }
}

@-webkit-keyframes animateDeleteIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateDeleteIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

.animateDeleteIcon {
  -webkit-animation: animateDeleteIcon 0.5s;
  animation: animateDeleteIcon 0.5s;
}

@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
