.dashbpard-sidebar {
  @apply relative h-screen;
  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, #c08414 0%, rgba(18, 18, 18, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
  }
  @include desktop-width {
    display: none;
  }
  &__menu {
    @apply mt-[70px] pr-[38px] pl-12 flex flex-col gap-6 text-title-lg;

    &--item {
      @apply flex gap-2 items-center text-blackshade-300 hover:text-blackshade-300;
      img {
        @apply w-8 h-8;
      }
      &.active {
        @apply text-gold-500;
      }
    }
  }
  &__nav {
    @apply flex flex-col justify-between fixed inset-0 overflow-auto bottom-0 top-0 left-0 bg-blackshade-900 z-40 h-[100vh] w-[300px] desktop:pb-[5rem];
    transition: all 0.3s ease;
  }
}

.dashboard-header {
  @apply flex justify-between px-8 py-4 relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #c08414 0%, rgba(18, 18, 18, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &__left {
    @apply flex gap-6 items-center;
    img {
      @apply w-14 h-14;
      @include desktop-width {
        @apply w-12 h-12;
      }
    }
  }
  &__right {
    @apply flex gap-6 items-center;
  }
  &__connect--btn {
    @include desktop-width {
      // display: none;
    }
   
  }
}
.dashboard-profile-col {
  &:not(:last-child) {
    // @apply pr-3;
    @include desktop-width {
      // @apply pb-3;
    }
  }
}
.dashboard-profile-card {
  @apply rounded-2xl flex p-3 md:p-4 justify-between flex-col h-[72px] md:h-[88px];

  background: var(
    --Informative_Frame,
    linear-gradient(
      116deg,
      rgba(18, 18, 18, 0.84) 26.5%,
      rgba(115, 98, 67, 0.84) 49.58%,
      rgba(18, 18, 18, 0.84) 99%
    )
  );
}
.dashboard-card {
  border-radius: 20px;
  background: var(--Dashboard-bg, rgba(31, 31, 31, 0.8));
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(3px);
  padding: 24px;
}
