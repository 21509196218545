$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1300px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900: 1900px;
@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin desktop-width {
  @media only screen and (max-width: $desktop-width) {
    @content;
  }
}
