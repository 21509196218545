.button {
  @apply flex gap-[10px] items-center justify-center;

  &.main {
    @apply text-blackshade-900 rounded-[100px] bg-golden-linear hover:bg-gold-700 hover:bg-none disabled:bg-gold-800;
    @apply active:bg-gold-500 active:bg-none;
    .button__loading li {
      @apply bg-blackshade-900;
    }
  }

  &.tonal {
    @apply bg-blackshade-700 text-gold-500 rounded-[100px] hover:bg-blackshade-600 hover:bg-none disabled:bg-blackshade-800;
    @apply active:bg-blackshade-500 active:bg-none;
    .button__loading li {
      @apply bg-gold-500;
    }
  }

  &.outlined {
    @apply border border-solid border-gold-500 text-gold-500 rounded-[100px] disabled:text-gold-800 disabled:border-gold-800 hover:text-gold-700 hover:border-gold-700;
    @apply active:text-gold-400 active:border-gold-400;
    .button__loading li {
      @apply bg-gold-500;
    }
  }

  &.ghost {
    @apply text-gold-500 rounded-[100px] disabled:text-gold-800 hover:text-gold-700;
    @apply active:text-gold-400;
    .button__loading li {
      @apply bg-gold-500;
    }
  }

  &.add {
    @apply border border-dotted border-gold-500 text-gold-500 rounded-[100px] disabled:text-gold-800 disabled:border-gold-800 hover:text-gold-700 hover:border-gold-700;
    @apply active:text-gold-400 active:border-gold-400;
    .button__loading li {
      @apply bg-gold-500;
    }
  }

  &.info {
    @apply border border-solid border-gray-300 text-gray-300 rounded-[100px] disabled:text-gray-700 disabled:border-gray-700 hover:text-gray-400 hover:border-gray-400;
    @apply active:text-gray-200 active:border-gray-200;
    .button__loading li {
      @apply bg-gray-300;
    }
  }

  &.error {
    @apply border border-solid border-orange-500 text-orange-500 rounded-[100px] disabled:text-orange-800 disabled:border-orange-800 hover:text-orange-700 hover:border-orange-700;
    @apply active:text-orange-400 active:border-orange-400;
    .button__loading li {
      @apply bg-orange-500;
    }
  }
  &.success {
    @apply border border-solid border-green-500 text-green-500 rounded-[100px] disabled:text-green-800 disabled:border-green-800 hover:text-green-700 hover:border-green-700;
    @apply active:text-green-400 active:border-green-400;
    .button__loading li {
      @apply bg-green-500;
    }
  }

  &.lg {
    @apply px-5 py-4 text-title-lg h-14;
  }
  &.md {
    @apply px-4 py-3 text-label-lg h-12;
  }
  &.sm {
    @apply px-3 py-2 text-label-lg h-10;
  }
  &.xsm {
    @apply p-[10px] text-body-lg h-8;
  }
  &__loading {
    @apply flex gap-1;
    li {
      @apply w-2 h-2 bg-white rounded-full list-none;
      animation: animate 1.6s ease-in-out infinite;
      &:nth-child(1) {
        animation-delay: -1.4s;
      }
      &:nth-child(2) {
        animation-delay: -1.2s;
      }
      &:nth-child(3) {
        animation-delay: -1s;
      }
    }
  }
}
@keyframes animate {
  0%,
  40%,
  100% {
    transform: scale(0.7);
  }

  20% {
    transform: scale(1);
  }
}
