.base-table {
  &__header {
    @apply flex justify-between  uppercase tracking-wider mb-6
     text-title-sm text-blackshade-900 p-6 rounded-[20px] overflow-x-auto;
    background: var(
      --Banner-Bg,
      linear-gradient(91deg, #ffb70f 3.57%, #8a5e0c 44.01%, #e5a000 99.84%)
    );
    backdrop-filter: blur(12px);
  }
  &__row {
    @apply text-white flex justify-between items-center px-4 py-[26px] mb-4 rounded-2xl overflow-x-auto;
    background: var(--Dashboard-bg, rgba(31, 31, 31, 0.8));
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(3px);
  }
}
